var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"cancellation-policy-categories"}},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('app-data-table',{ref:"refCancelPolCat",attrs:{"table-name":"cancellation-policy-categories","fields":_vm.cancellationPolicyCategoriesFields,"items":_vm.cancellationPolicyCategories,"busy":_vm.cancellationPolicyLoading,"sort-by":"categoryName","top-table":{
        showPerPage: _vm.$can('CANCELLATION_POLICY_VIEW'),
        showAdd: _vm.$can('CANCELLATION_POLICY_ADD'),
        disabled: _vm.cancellationPolicyLoading,
      },"actions-table":{
        showEdit: _vm.$can('CANCELLATION_POLICY_EDIT'),
        showDelete: _vm.$can('CANCELLATION_POLICY_DELETE'),
        showDuplicate: _vm.$can('CANCELLATION_POLICY_ADD'),
      },"bottom-table":{ totalCount: _vm.cancellationPolicyCategories.length }},on:{"table-add":function($event){return _vm.addCategory()},"duplicate":function($event){return _vm.duplicateCategory($event.item)},"edit":function($event){return _vm.editCategory($event.item)},"delete":function($event){return _vm.removeCategory($event.item.categoryId)}},scopedSlots:_vm._u([{key:"cell(category)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold d-block",on:{"click":function($event){return _vm.editCategory(data.item)}}},[_vm._v(" "+_vm._s(_vm._f("trans")(data.item.categoryName))+" ")])]}},{key:"cell(conditions)",fn:function(data){return _vm._l((data.item.conditions),function(condition){return _c('div',{key:condition.id,staticClass:"text-nowrap"},[(condition.ifClause)?_c('span',[_vm._v(" "+_vm._s(_vm._f("enumTranslate")(condition.ifClause,'cancellation_policy_condition_if'))+", "),_c('strong',{staticClass:"condition-rule"},[_vm._v(" "+_vm._s(_vm._f("enumTranslate")(condition.ruleClause,'cancellation_policy_condition_rule')))]),_vm._v(" "+_vm._s(_vm.$t('cancellation_policy.condition.with_rate'))+" "),_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(condition.priceRate)+"%")])],1):_vm._e()])})}}])})],1),_c('CancellationPolicyCategory',{attrs:{"category-cp":_vm.selectedCategory},on:{"update:category":function($event){return _vm.updateCategories($event)}},model:{value:(_vm.categoryEditionMode),callback:function ($$v) {_vm.categoryEditionMode=$$v},expression:"categoryEditionMode"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }