<template>
  <div id="cancellation-policy-category-conditions">
    <div v-if="value.length" class="repeater-form">
      <!-- Row Loop -->
      <b-row v-for="(condition, index) in value" :id="condition.id" :key="condition.id" ref="row">
        <!-- Condition -->
        <b-col cols="12">
          <enum-select
            id="cancellationPolicyConditionIf"
            v-model="condition.ifClause"
            enum-class="CancellationPolicyConditionIf"
            :label="$t('cancellation_policy.condition.ifClause')"
            required
          />
        </b-col>

        <!-- Rule -->
        <b-col cols="12">
          <enum-select
            id="cancellationPolicyConditionRule"
            v-model="condition.ruleClause"
            enum-class="CancellationPolicyConditionRule"
            :label="$t('cancellation_policy.condition.ruleClause')"
            required
          />
        </b-col>

        <!-- Rate -->
        <b-col cols="12">
          <app-input
            id="cancellationPolicyPriceRate"
            v-model.number="condition.priceRate"
            :label="$t('cancellation_policy.condition.priceRate')"
            label-cols-md="5"
            rules="required|between:0,100"
            type="number"
            step="0.01"
            size="sm"
          />
        </b-col>

        <!-- Remove Button -->
        <b-col v-if="value.length > 1" cols="12">
          <b-button id="removeCondition" variant="outline-danger" size="sm" @click="removeCondition(index)">
            <feather-icon icon="XIcon" class="mr-25" />
            <span>{{ $t('action.delete') }}</span>
          </b-button>
        </b-col>

        <b-col cols="12" class="mt-25"><hr /></b-col>
      </b-row>
    </div>

    <app-btn-add class="pl-2" @click="addCondition()" />
  </div>
</template>

<script>
import AppBtnAdd from '@/components/AppBtnAdd.vue'

export default {
  name: 'CancellationPolicyCategoryConditions',

  components: {
    AppBtnAdd,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    addCondition() {
      this.value.push({
        id: null,
        ifClause: '',
        ruleClause: '',
        priceRate: 0,
      })
    },

    removeCondition(index) {
      this.value.splice(index, 1)
    },
  },
}
</script>
<style lang="scss" scoped>
.repeater-form {
  transition: 0.35s height;
}
</style>
