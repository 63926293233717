<template>
  <div id="cancellation-policy-categories">
    <b-card no-body class="mb-0">
      <app-data-table
        ref="refCancelPolCat"
        table-name="cancellation-policy-categories"
        :fields="cancellationPolicyCategoriesFields"
        :items="cancellationPolicyCategories"
        :busy="cancellationPolicyLoading"
        sort-by="categoryName"
        :top-table="{
          showPerPage: $can('CANCELLATION_POLICY_VIEW'),
          showAdd: $can('CANCELLATION_POLICY_ADD'),
          disabled: cancellationPolicyLoading,
        }"
        :actions-table="{
          showEdit: $can('CANCELLATION_POLICY_EDIT'),
          showDelete: $can('CANCELLATION_POLICY_DELETE'),
          showDuplicate: $can('CANCELLATION_POLICY_ADD'),
        }"
        :bottom-table="{ totalCount: cancellationPolicyCategories.length }"
        @table-add="addCategory()"
        @duplicate="duplicateCategory($event.item)"
        @edit="editCategory($event.item)"
        @delete="removeCategory($event.item.categoryId)"
      >
        <!-- CELL -->
        <!--category-->
        <template #cell(category)="data">
          <b-link class="font-weight-bold d-block" @click="editCategory(data.item)">
            {{ data.item.categoryName | trans }}
          </b-link>
        </template>
        <!-- conditions -->
        <template #cell(conditions)="data">
          <div v-for="condition in data.item.conditions" :key="condition.id" class="text-nowrap">
            <span v-if="condition.ifClause">
              {{ condition.ifClause | enumTranslate('cancellation_policy_condition_if') }},
              <strong class="condition-rule"> {{ condition.ruleClause | enumTranslate('cancellation_policy_condition_rule') }}</strong>
              {{ $t('cancellation_policy.condition.with_rate') }}
              <b-badge pill variant="light-primary"> {{ condition.priceRate }}%</b-badge>
            </span>
          </div>
        </template>
      </app-data-table>
    </b-card>
    <CancellationPolicyCategory v-model="categoryEditionMode" :category-cp="selectedCategory" @update:category="updateCategories($event)" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { lowerCase, upperFirst } from 'lodash'

import AppDataTable from '@/components/AppDataTable.vue'
import CancellationPolicyCategory from './CancellationPolicyCategory.vue'

export default defineComponent({
  name: 'CancellationPolicyCategories',

  components: {
    CancellationPolicyCategory,
    AppDataTable,
  },

  props: {
    cancellationPolicyCategories: {
      type: Array,
      required: true,
    },
    cancellationPolicyLoading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      categoryEditionMode: null,
      selectedCategory: {},
      cancellationPolicyCategoriesFields: [
        { key: 'category', label: this.$t('cancellation_policy.category.title'), sortable: true },
        { key: 'conditions', label: this.$t('cancellation_policy.condition.title') },
      ],
    }
  },

  methods: {
    upperFirst,
    lowerCase,

    addCategory() {
      this.selectedCategory = {
        categoryId: null,
        categoryName: {},
        conditions: [{ id: null }],
      }
      this.categoryEditionMode = 'add'
    },

    removeCategory(catId) {
      const copyCategoriesCP = this.cancellationPolicyCategories.filter(catCP => catCP.categoryId !== catId)
      this.$emit('update:cancellation-policy-categories', copyCategoriesCP)
    },

    editCategory(category) {
      this.selectedCategory = category
      this.categoryEditionMode = 'edit'
    },

    duplicateCategory(category) {
      this.selectedCategory = {
        categoryId: null,
        categoryName: {},
        conditions: category.conditions.map(condition => ({ ...condition, id: null })),
      }
      this.categoryEditionMode = 'duplicate'
    },

    updateCategories({ categoryCP, categories }) {
      const copyCategoriesCP = this._cloneDeep(this.cancellationPolicyCategories)
      categories.forEach(category => {
        const index = copyCategoriesCP.findIndex(item => item.categoryId === category.categoryId)
        const finalCategoriesCP = { ...categoryCP, ...category }

        // ADD
        if (index === -1) copyCategoriesCP.push(finalCategoriesCP)
        // EDIT
        else copyCategoriesCP[index] = finalCategoriesCP
      })
      this.$emit('update:cancellation-policy-categories', copyCategoriesCP)
      this.$refs.refCancelPolCat.refresh()
    },
  },
})
</script>

<style lang="scss" scoped>
.condition-rule {
  color: #28c76f;
}
</style>
