<template>
  <!-- TODO fix snychronise options, refresh list after update available, select unique -->
  <div :id="`${id}-category-tree-select`">
    <!-- :load-options="loadOptions" -->
    <treeselect
      :value="valueFormat === 'id' ? valueId : value"
      :allow-clearing-disabled="allowClearingDisabled"
      :allow-selecting-disabled-descendants="allowSelectingDisabledDescendants"
      :always-open="alwaysOpen"
      :append-to-body="appendToBody"
      :async="async"
      :auto-focus="autoFocus"
      :auto-load-root-options="autoLoadRootOptions"
      :auto-deselect-ancestors="autoDeselectAncestors"
      :auto-deselect-descendants="autoDeselectDescendants"
      :auto-select-ancestors="autoSelectAncestors"
      :auto-select-descendants="autoSelectDescendants"
      :backspace-removes="backspaceRemoves"
      :before-clear-all="beforeClearAll"
      :branch-nodes-first="branchNodesFirst"
      :cache-options="cacheOptions"
      :clearable="clearable"
      :clear-all-text="$t('category.clear_all_text')"
      :clear-on-select="clearOnSelect"
      :clear-value-text="$t('category.clear_value_text')"
      :close-on-select="closeOnSelect"
      :default-expand-level="defaultExpandLevel"
      :default-options="defaultOptions"
      :delete-removes="deleteRemoves"
      :delimiter="delimiter"
      :flatten-search-results="flattenSearchResults"
      :disable-branch-nodes="disableBranchNodes"
      :disabled="disabled"
      :disable-fuzzy-matching="disableFuzzyMatching"
      :flat="flat"
      :instance-id="`${id}-category-tree-select`"
      :join-values="joinValues"
      :limit="limit"
      :limit-text="count => `${$t('category.limit_text_and')} ${count} ${$t('category.limit_text_more')}`"
      :loading-text="$t('category.loading_text')"
      :match-keys="matchKeys"
      :max-height="maxHeight"
      :multiple="multiple"
      :name="`${id}-category-tree-select`"
      :no-children-text="$t('category.no_children_text')"
      :no-options-text="$t('category.no_options_text')"
      :no-results-text="$t('category.no_results_text')"
      :normalizer="categoriesNormalizer"
      :open-direction="openDirection"
      :open-on-click="openOnClick"
      :open-on-focus="openOnFocus"
      :options="filteredCategories"
      :placeholder="multiple ? $tc('category.placeholder', 0) : $tc('category.placeholder', 1)"
      :required="required"
      :retry-text="$t('category.retry_text')"
      :retry-title="$t('category.retry_title')"
      :searchable="searchable"
      :search-nested="searchNested"
      :search-prompt-text="$t('category.search_prompt_text')"
      :show-count="showCount"
      :show-count-of="showCountOf"
      :show-count-on-search="showCountOnSearch"
      :sort-value-by="sortValueBy"
      :tab-index="tabIndex"
      :value-consists-of="valueConsistsOf"
      :value-format="valueFormat"
      :z-index="zIndex"
      v-on="$listeners"
    />
    <!-- @select="value => $emit('select', value)"
    @close="value => $emit('input', value)" -->
  </div>
</template>

<script>
// SEE https://vue-treeselect.js.org/
import Treeselect from '@riophae/vue-treeselect'
import '@core/scss/vue/libs/vue-treeselect.scss'

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'CategoryTreeSelect',

  components: {
    Treeselect,
  },

  props: {
    // Additional props
    id: { type: String, default: '' },
    value: { type: [Array, Object], default: null },
    valueId: { type: Number, default: null },
    lang: { type: String, default: null },
    active: { type: Boolean, default: false },
    depth: { type: Number, default: 0 }, // 0 for get all node depth
    /* TODO à terminer
     synchronize: {
      type: Boolean,
      default: false,
    },
    synchronizeId: {
      type: String,
      default: 'offerCommission',
    }, */
    // Base props
    allowClearingDisabled: { type: Boolean, default: true },
    allowSelectingDisabledDescendants: { type: Boolean, default: true },
    alwaysOpen: { type: Boolean, default: false },
    appendToBody: { type: Boolean, default: false },
    async: { type: Boolean, default: false },
    autoFocus: { type: Boolean, default: false },
    autoLoadRootOptions: { type: Boolean, default: true },
    autoDeselectAncestors: { type: Boolean, default: false },
    autoDeselectDescendants: { type: Boolean, default: false },
    autoSelectAncestors: { type: Boolean, default: false },
    autoSelectDescendants: { type: Boolean, default: false },
    backspaceRemoves: { type: Boolean, default: true },
    beforeClearAll: { type: Function, default: () => true },
    branchNodesFirst: { type: Boolean, default: false },
    cacheOptions: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true },
    closeOnSelect: { type: Boolean, default: true },
    clearOnSelect: { type: Boolean, default: multiple => !multiple },
    defaultExpandLevel: { type: Number, default: 0 },
    defaultOptions: { type: Boolean, default: false },
    deleteRemoves: { type: Boolean, default: false },
    delimiter: { type: String, default: ',' },
    disableBranchNodes: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disableFuzzyMatching: { type: Boolean, default: false },
    flattenSearchResults: { type: Boolean, default: true },
    flat: { type: Boolean, default: false },
    joinValues: { type: Boolean, default: false },
    limit: { type: Number, default: Infinity },
    // loadOptions: {
    //   type: Function,
    // },
    matchKeys: { type: Array, default: () => ['label'] },
    maxHeight: { type: Number, default: 300 },
    multiple: { type: Boolean, default: true },
    // openDirection: {
    //   type: String,
    //   default: 'below',
    // },
    openOnClick: { type: Boolean, default: true },
    openOnFocus: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    searchNested: { type: Boolean, default: false },
    showCount: { type: Boolean, default: true },
    showCountOf: { type: String, default: 'ALL_CHILDREN' },
    showCountOnSearch: { type: Boolean, default: false },
    sortValueBy: { type: String, default: 'LEVEL' },
    tabIndex: { type: Number, default: 0 },
    valueConsistsOf: { type: String, default: 'BRANCH_PRIORITY' },
    valueFormat: { type: String, default: 'object' },
    zIndex: { type: Number, default: 999 },
  },

  data() {
    return {
      openDirection: 'below', // WARNING do not override this value
    }
  },

  computed: {
    ...mapState('category', ['categories', 'categoriesLoading']),
    ...mapGetters('category', ['categoriesById']),

    // TODO create dynamic store for crossfields validation

    localLang() {
      return this.lang || this.$store.state.auth.defaultLang
    },
    filteredCategories() {
      let filteredCategories = this._cloneDeep(this.categories)
      if (this.active) {
        filteredCategories = this.recursiveFilterActiveCategories(filteredCategories)
      }
      // if (this.depth > 0) {
      //   const depthCategories = filteredCategories
      //   this.filterDepthCategories(depthCategories, this.depth)
      //   filteredCategories = depthCategories
      // }
      if (this.synchronize) {
        filteredCategories = this.categoriesById(this.synchronizeId) //
      }

      return filteredCategories
    },
  },

  watch: {
    value: {
      handler() {
        if (this.synchronize && this.value) {
          const value = this.updateAvailableItemSelection(this.value, this.categoriesById(this.synchronizeId))
          this.setTemporaryCategories({
            id: this.synchronizeId,
            value, //
          })
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (!this.categories.length) this.fetchCategories()
    if (this.synchronize) this.fetchCategories({ synchronizeId: this.synchronizeId })
  },

  beforeDestroy() {
    if (this.synchronize) this.deleteTemporaryCategories(this.synchronizeId)
  },

  methods: {
    ...mapActions('category', ['fetchCategories', 'setTemporaryCategories', 'deleteTemporaryCategories']),

    categoriesNormalizer(node) {
      return {
        id: node.id,
        label: node.name[this.localLang],
        children: node.children,
      }
    },

    loadOptions() {
      return this.filteredCategories
    },

    recursiveFilterActiveCategories(categories) {
      const activeCategories = categories
      function filterActiveCategories(categories) {
        categories.forEach((category, index, categories) => {
          category.active && categories.splice(index, 1)
          category.children && filterActiveCategories(category.children)
        })
      }
      filterActiveCategories(activeCategories)
      return activeCategories
    },

    // filterDepthCategories(categories, depth) {
    //   categories.forEach((category, index, categories) => {
    //     category.nodeDepth - 1 > depth && categories.splice(index, 1)
    //     category.children
    //       && this.filterDepthCategories(category.children, depth)
    //   })
    // },

    updateAvailableItemSelection(selectedCategories, options) {
      const availableCategories = options || []
      function disableCategories(categories) {
        categories.forEach(category => {
          const found = selectedCategories.find(selectedCategory => selectedCategory.id === category.id)
          found ? (category.isDisabled = true) : (category.isDisabled = false)
          category.children && category.children.length && disableCategories(category.children)
        })
      }

      disableCategories(availableCategories)
      return availableCategories
    },
  },
}
</script>
