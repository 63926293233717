<template>
  <div id="cancellation-policy">
    <b-card>
      <validation-observer ref="cancellation-policy-group-observer">
        <b-form ref="cancellation-policy-group-form">
          <b-row>
            <b-col cols="12" md="8">
              <b-row>
                <b-col cols="12" md="8" xl="10">
                  <app-input
                    id="priceManagementGroupName"
                    v-model="cancellationPolicy.name"
                    :label="$t('cancellation_policy.group.name')"
                    :overlay="cancellationPolicyLoading"
                    required
                  />
                </b-col>
                <b-col cols="12" md="4" xl="2">
                  <enum-select
                    id="priceManagementStatus"
                    v-model="cancellationPolicy.status"
                    :label="$t('common.status')"
                    enum-class="CancellationPolicyStatus"
                    :overlay="cancellationPolicyLoading"
                    :clearable="false"
                    :searchable="false"
                    required
                  />
                </b-col>
              </b-row>

              <validation-provider #default="{ errors }" :name="$t('common.description')">
                <b-form-group :label="$t('common.description')" label-for="cancellation-policy-group-description">
                  <b-overlay :show="cancellationPolicyLoading">
                    <b-form-textarea
                      id="cancellation-policy-group-description"
                      v-model="cancellationPolicy.description"
                      :placeholder="$t('common.description')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-overlay>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-overlay :show="cancellationPolicyLoading || dropZoneLoading" spinner-medium>
                <app-drop-zone
                  id="cancellation-policy-terms"
                  ref="cancellation-policy-terms"
                  :options="dropzoneOptions"
                  :disabled="cancellationPolicyLoading || dropZoneLoading"
                  class="mt-2"
                  @vdropzone-queue-complete="pdfUploaded = true"
                  @vdropzone-removed-file="removeCancellationPolicyConditionsFile()"
                  @vdropzone-file-added="sendCancellationPolicyConditionsFile($event)"
                />
              </b-overlay>
            </b-col>
          </b-row>
          <b-row class="ml-1">
            <b-overlay :show="cancellationPolicyLoading" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
              <b-form-checkbox v-model="cancellationPolicy.default" switch inline>
                {{ $t('common.default') }}
              </b-form-checkbox>
            </b-overlay>
          </b-row>
          <b-row class="ml-1 mt-25">
            <p>{{ $t('common.notice') }} {{ $t('cancellation_policy.default_notice') }}</p>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <CancellationPolicyCategories
      :cancellation-policy-categories="cancellationPolicy.cancellationPolicyCategories"
      :cancellation-policy-loading="cancellationPolicyLoading"
      @update:cancellation-policy-categories="cancellationPolicy.cancellationPolicyCategories = $event"
    />
    <b-card class="mt-2">
      <b-row>
        <!-- Cancel  -->
        <b-col class="d-flex justify-content-end">
          <b-overlay :show="cancellationPolicyLoading">
            <b-button
              id="cancel-cancellation-policies"
              type="link"
              :to="{ name: 'cancellationPolicies' }"
              variant="outline-secondary"
              :disabled="cancellationPolicyLoading"
            >
              <font-awesome-icon icon="ban" class="mr-50" />
              {{ $t('action.cancel') }}
            </b-button>
          </b-overlay>
          <b-overlay :show="cancellationPolicyLoading">
            <b-button
              id="submit-cancellation-policies"
              type="submit"
              class="ml-2"
              :disabled="cancellationPolicyLoading || !pdfUploaded || !cancellationPolicy.cancellationPolicyCategories.length"
              @click.prevent="submitCancellationPolicy"
            >
              <font-awesome-icon icon="user-plus" class="mr-50" />
              {{ $route.params.copy ? $t('action.duplicate') : $t('action.save') }}
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  fetchCancellationPolicyRequest,
  patchCancellationPolicyRequest,
  postCancellationPolicyRequest,
} from '@/request/globalApi/requests/cancellationPolicyRequests'
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'

import { mapActions, mapGetters } from 'vuex'

import { lowerCase, upperFirst } from 'lodash'
import AppDropZone from '@/components/AppDropZone.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CancellationPolicyCategories from './CancellationPolicyCategories.vue'

export default {
  name: 'CancellationPolicy',

  components: {
    CancellationPolicyCategories,
    AppDropZone,
  },
  data() {
    return {
      pdfUploaded: true,
      cancellationPolicyLoading: false,
      cancellationPolicy: {
        id: null,
        name: '',
        description: '',
        termsFileId: null,
        cancellationPolicyCategories: [],
        deleteOldTermsFile: false,
        status: 'active',
      },
      dropZoneLoading: false,
      dropzoneOptions: {
        autoProcessQueue: true,
        url: 'https://localhost',
        dictDefaultMessage: this.$t('cancellation_policy.condition.upload'),
        thumbnailWidth: 250,
        maxFiles: 1,
        maxFilesize: 2000000,
        acceptedFiles: '.png,.jpeg,.pdf,.x-pdf,.gif,.csv,.txt,.xml',
        //  "texte simple",
        //  "application/vnd.ms-excel",
        //  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

        clickable: true,
      },
    }
  },
  computed: {
    ...mapGetters('cancellationPolicy', ['cancellationPolicyById']),
  },

  watch: {
    // eslint-disable-next-line func-names
    'cancellationPolicy.termsFileUrl': function (value) {
      if (value) {
        const file = { size: 2000000, name: this.cancellationPolicy.termsFilePath, type: '' }
        this.$refs['cancellation-policy-terms'].manuallyAddFile(file, value)
      }
    },
  },

  mounted() {
    // if edit or duplicate
    if (this.$route.name !== 'cancellationPolicyAdd') {
      // if already loaded one time
      if (this.cancellationPolicyById(this.$route.params.id)) {
        this.cancellationPolicy = { ...this.cancellationPolicyById(this.$route.params.id) }
        // if duplicate
        if (this.$route.name === 'cancellationPolicyDuplicate') {
          this.duplicatCancellationPolicy()
        }
      } else {
        // if never loaded
        this.cancellationPolicyLoading = true
        fetchCancellationPolicyRequest(this.$route.params.id)
          .then(response => {
            this.cancellationPolicy = response.data.cancellationPolicy
            this.setCancelationPoliciesShowed({ ...this.cancellationPolicy })
          })
          .then(() => {
            // if duplicate
            if (this.$route.name === 'cancellationPolicyDuplicate') {
              this.duplicatCancellationPolicy()
            }
          })
          .finally(() => {
            this.cancellationPolicyLoading = false
          })
      }
    }
  },

  methods: {
    ...mapActions('cancellationPolicy', ['setCancelationPoliciesShowed', 'clearCancelationPoliciesShowed']),

    upperFirst,
    lowerCase,

    submitCancellationPolicy() {
      this.$refs['cancellation-policy-group-observer'].validate().then(success => {
        if (success && this.cancellationPolicy.cancellationPolicyCategories.length) {
          let action = patchCancellationPolicyRequest
          if (this.$route.name !== 'cancellationPolicyEdit') {
            this.cancellationPolicy.id = null
            action = postCancellationPolicyRequest
          }
          action(this.cancellationPolicy, this.cancellationPolicy.name).then(() => {
            this.$router.push({ name: 'cancellationPolicies', params: { refresh: true } })
          })
        }
      })
    },

    // Duplicate a cancellation policy and set it's category conditions id to null
    duplicatCancellationPolicy() {
      this.cancellationPolicy.name = `${this.cancellationPolicy.name}_copy`
      this.cancellationPolicy.cancellationPolicyCategories = this.cancellationPolicy.cancellationPolicyCategories.map(category => ({
        ...category,
        conditions: category.conditions.map(cond => ({ ...cond, id: null })),
      }))
    },

    removeCancellationPolicyConditionsFile() {
      this.cancellationPolicy.termsFileId = null
      this.cancellationPolicy.deleteOldTermsFile = true
      delete this.cancellationPolicy.termsFilePath
      delete this.cancellationPolicy.termsFileUrl
    },

    sendCancellationPolicyConditionsFile(file) {
      if (file.size > this.dropzoneOptions.maxFilesize) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('file.max_file_size_reached'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.$refs['cancellation-policy-terms'].removeFile(file)
        return
      }

      if (!this.cancellationPolicy.termsFileUrl) {
        this.dropZoneLoading = true
        this.pdfUploaded = false
        const formData = new FormData()
        formData.append('temporary_file', file)
        postFileRequest(formData)
          .then(response => {
            this.cancellationPolicy.termsFileId = response.data.id
            this.cancellationPolicy.deleteOldTermsFile = false
          })
          .finally(() => {
            this.pdfUploaded = true
            this.dropZoneLoading = false
          })
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.matched[0].path !== '/cancellation-policies') {
      this.clearCancelationPoliciesShowed()
    }
    next()
  },
}
</script>
